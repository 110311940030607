/** @jsx jsx */
import { Heading, Text, jsx, Container, Divider } from "theme-ui";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { Stack } from "raam";
import MainLayout from "./MainLayout";
import Header from "../components/Header";
import BlogPostCard from "../components/Cards/BlogPostCard";

const CategoryLayout = ({
  data: {
    wpCategory: {
      description,
      name,
      posts: { nodes: posts },
    },
  },
  location: { pathname },
}) => (
  <MainLayout description={description} pathname={pathname} title={name}>
    <Header
      pathname={pathname}
      sx={{ background: "linear-gradient(to right top, #07112b, #142b53)" }}
    />
    <Container px="3" py="8" sx={{ maxWidth: "5xl" }}>
      <Heading as="h1" variant="text.heading.two">
        {name}
      </Heading>
      {description && (
        <Text as="p" mt="3" variant="blog.card.excerpt">
          {description}
        </Text>
      )}
      <Divider my={["4", null, "6"]} variant="divider.light" />
      <Stack gap="8">
        {posts.map((post) => (
          <BlogPostCard key={post.id} post={post} />
        ))}
      </Stack>
    </Container>
  </MainLayout>
);

CategoryLayout.propTypes = {
  data: PropTypes.shape({
    wpCategory: PropTypes.shape({
      description: PropTypes.string,
      name: PropTypes.string,
      posts: PropTypes.shape({
        nodes: PropTypes.arrayOf(
          PropTypes.shape({
            customFields: PropTypes.shape({
              subtitle: PropTypes.string,
            }),
            date: PropTypes.string,
            excerpt: PropTypes.string,
            id: PropTypes.string,
            title: PropTypes.string,
            uri: PropTypes.string,
          })
        ),
      }),
    }),
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

export default CategoryLayout;

export const CategoryQuery = graphql`
  query CategoryPageQuery($id: String) {
    wpCategory(id: { eq: $id }) {
      description
      id
      name
      posts {
        nodes {
          customFields {
            subtitle
          }
          date(formatString: "MMM Do, YYYY")
          excerpt
          id
          title
          uri
        }
      }
    }
  }
`;
